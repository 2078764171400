import React, { Component } from 'react';
import ReactFancyBox from 'react-fancybox';
import 'react-fancybox/lib/fancybox.css';
import Sectiontitle from '../section-title';
import './style.css';

import galimg1 from '../../images/selfie-cali.jpg';
import galimg2 from '../../images/snowy-trellace.jpg';
import galimg3 from '../../images/elevator-selfie.jpg';
import galimg4 from '../../images/newport-wedding.jpg';
import galimg5 from '../../images/boat-selfie.jpg';
import galimg6 from '../../images/seflie-christmas.jpg';

import galimg7 from '../../images/selfie-codzilla.jpg';
import galimg8 from '../../images/selfie-floral-katie-wedding.jpg';
import galimg9 from '../../images/selfie-pontoon.jpg';
import galimg10 from '../../images/pebbles-dock.jpg';
import galimg11 from '../../images/pebbles-jack.jpg';
import galimg12 from '../../images/selfie-baby.jpg';

class Gallery extends Component {
    render() {
        return (
            <div id="gallery" className="Gallery-section section-padding pb-70">
                <Sectiontitle section={'Our Gallery'} />
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                            <div className="gallery-img">
                                <ReactFancyBox
                                    thumbnail={galimg1}
                                    image={galimg1}
                                />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                            <div className="gallery-img">
                                <ReactFancyBox
                                    thumbnail={galimg2}
                                    image={galimg2}
                                />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                            <div className="gallery-img">
                                <ReactFancyBox
                                    thumbnail={galimg3}
                                    image={galimg3}
                                />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                            <div className="gallery-img">
                                <ReactFancyBox
                                    thumbnail={galimg4}
                                    image={galimg4}
                                />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                            <div className="gallery-img">
                                <ReactFancyBox
                                    thumbnail={galimg5}
                                    image={galimg5}
                                />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                            <div className="gallery-img">
                                <ReactFancyBox
                                    thumbnail={galimg6}
                                    image={galimg6}
                                />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                            <div className="gallery-img">
                                <ReactFancyBox
                                    thumbnail={galimg7}
                                    image={galimg7}
                                />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                            <div className="gallery-img">
                                <ReactFancyBox
                                    thumbnail={galimg8}
                                    image={galimg8}
                                />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                            <div className="gallery-img">
                                <ReactFancyBox
                                    thumbnail={galimg9}
                                    image={galimg9}
                                />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                            <div className="gallery-img">
                                <ReactFancyBox
                                    thumbnail={galimg10}
                                    image={galimg10}
                                />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                            <div className="gallery-img">
                                <ReactFancyBox
                                    thumbnail={galimg11}
                                    image={galimg11}
                                />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                            <div className="gallery-img">
                                <ReactFancyBox
                                    thumbnail={galimg12}
                                    image={galimg12}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Gallery;
