import React from 'react';
import Slider from 'react-slick';

import Sectiontitle from '../section-title';
import exchangeCenterParty from '../../images/exchange-inside.jpg';
import exchangeCenterOutside from '../../images/exchange-center.jpg';
import attireImage1 from '../../images/attire-take-two.jpg';
import attireImage2 from '../../images/attire-take-one.jpg';
import attireImage3 from '../../images/attire-take-three.jpg';
import attireImage4 from '../../images/attire-4.jpg';
import postable from '../../images/save-the-date-postable.jpg';
import fauci from '../../images/fauci.jpg';

import './style.css';

const Location = () => {
    var settings = {
        dots: false,
        arrows: true,
        speed: 1200,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2500,
        fade: true,
    };
    return (
        <div id="event" className="service-area section-padding">
            <div className="container">
                <Sectiontitle section={'The Wedding'} />
                <div className="service-area-menu">
                    <div className="Ceremony-wrap">
                        <div className="row">
                            <div className="col-lg-5">
                                <div className="ceromony-img">
                                    <img src={exchangeCenterOutside} alt="" />
                                </div>
                            </div>
                            <div className="col-lg-7">
                                <div className="ceromony-content">
                                    <h3>Ceremony</h3>
                                    <p style={{ padding: '10px 0' }}>
                                        Join us as Pebbles (and Jack!) realize
                                        their ultimate goal for us: to get
                                        married.
                                    </p>
                                    <span>
                                        Saturday, May 14th, 2022 at 5:30pm
                                    </span>
                                    <a
                                        href="https://www.google.com/maps/place/The+Exchange+Conference+Center/@42.3519153,-71.0396118,17z/data=!3m2!4b1!5s0x89e37077645fdbd3:0x54cdd9365272c4ab!4m5!3m4!1s0x89e37077648e2af3:0xe92fff83c5794e2f!8m2!3d42.3517849!4d-71.0375188"
                                        target="_blank"
                                        style={{
                                            marginTop: '5px',
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        The Exchange
                                    </a>
                                    <span style={{ fontStyle: 'italic' }}>
                                        at Fish Pier
                                    </span>
                                    <span>212 Northern Avenue</span>
                                    <span>Boston, MA 02210</span>
                                    <span
                                        style={{
                                            marginTop: '5px',
                                            fontSize: '0.875rem',
                                        }}
                                    >
                                        Complimentary parking is available
                                        on-site
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="Ceremony-wrap">
                        <div className="row">
                            <div className="col-lg-5">
                                <Slider {...settings}>
                                    <img src={attireImage1} alt="" />
                                    <img src={attireImage2} alt="" />
                                    <img src={attireImage3} alt="" />
                                    <img src={attireImage4} alt="" />
                                </Slider>
                            </div>
                            <div className="col-lg-7">
                                <div className="ceromony-content">
                                    <h3>Dress Code</h3>
                                    <p>
                                        <a
                                            href="https://www.brides.com/cocktail-attire-wedding-4844364"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            Cocktail Attire
                                        </a>
                                        : A balance between formal and casual
                                        and elegant and comfortable. A step
                                        above semi-formal but not as formal as
                                        black-tie optional. Keep it classic or
                                        feel free to be a little colorful.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="Ceremony-wrap">
                        <div className="row">
                            <div className="col-lg-5">
                                <div className="ceromony-img">
                                    <img src={exchangeCenterParty} alt="" />
                                </div>
                            </div>
                            <div className="col-lg-7">
                                <div className="ceromony-content">
                                    <h3>Reception</h3>
                                    <p>
                                        Our reception will be a little
                                        untraditional. While there will be
                                        plenty of food throughout the evening
                                        and ample seating available, we would
                                        love for you to mix and mingle with
                                        relatives and friends, so you won't find
                                        any name cards or assigned tables. Think
                                        of it as an infinity cocktail hour
                                        (everyone's favorite part of any
                                        wedding).
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="Ceremony-wrap">
                        <div className="row">
                            <div className="col-lg-5">
                                <div className="ceromony-img">
                                    <img src={postable} alt="" />
                                </div>
                            </div>
                            <div className="col-lg-7">
                                <div className="ceromony-content">
                                    <h3>Contact Information</h3>
                                    <p>
                                        Help us keep in touch. We’re using
                                        Postable to manage contact information
                                        for our guests. Should your address or
                                        name need correcting or updating, please
                                        do so at{' '}
                                        <a
                                            href="http://www.postable.com/ericshauna"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            http://www.postable.com/ericshauna
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="Ceremony-wrap">
                        <div className="row">
                            <div className="col-lg-5">
                                <div className="ceromony-img">
                                    <img src={fauci} alt="" />
                                </div>
                            </div>
                            <div className="col-lg-7">
                                <div className="ceromony-content">
                                    <h3 style={{ marginTop: '10px' }}>
                                        COVID Information
                                    </h3>
                                    <p className="mb-0">
                                        We're cautiously optimistic that the
                                        pandemic phase of COVID is nearing an
                                        end and Boston currently has no COVID
                                        restrictions or mandates. Should the
                                        situation change, we will make an
                                        informed decision based on CDC
                                        recommendations and city of Boston
                                        mandates for large events regarding
                                        masking, testing or vaccination
                                        policies. As of now, we do not plan to
                                        have any such policies but will promptly
                                        email our guests with any updates. We
                                        encourage all of our guests to continue
                                        to do what feels safest for them and
                                        wear a mask if they choose.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Location;
