import React, { Component, Fragment } from 'react';
import CountDown from 'reactjs-countdown';
import GoodCountdown from 'react-countdown';

import './style.css';

const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
        // Render a complete state
        return <div>It happened!</div>;
    } else {
        // Render a countdown
        return (
            <Fragment>
                <ul className="react-countdown text-center">
                    <li>
                        <p className="digit">{days}</p>
                        <p className="text">days</p>
                    </li>
                    <li>
                        <p className="digit">{hours}</p>
                        <p className="text">hours</p>
                    </li>
                    <li>
                        <p className="digit">{minutes}</p>
                        <p className="text">min</p>
                    </li>
                    <li>
                        <p className="digit">{seconds}</p>
                        <p className="text">Sec</p>
                    </li>
                </ul>
            </Fragment>
        );
    }
};

class Saveday extends Component {
    render() {
        return (
            <div className="count-down-area">
                <div className="count-down-sectionparallax">
                    <div className="container">
                        <div className="count-down-item count-down-item2">
                            <div className="row">
                                <div className="col-12 col-md-4 section-area">
                                    <div className="section-sub"></div>
                                    <h2 className="big">Counting Down</h2>
                                </div>
                                <div className="col-12 col-md-8 clock-area">
                                    <div className="count-down-clock">
                                        <GoodCountdown
                                            date={1652520600000}
                                            renderer={renderer}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Saveday;
