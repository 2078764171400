import React from 'react';

import './style.css';
// components
import Navbar from '../../components/Navbar';

const Events = () => {
    return (
        <div>
            <Navbar />
            <div className="events-content"></div>
        </div>
    );
};

export default Events;
