import React from 'react';

// components

import SimpleSlider from '../../components/hero';

import Welcome from '../../components/welcome-area';

import Location from '../../components/location';
import Gallery from '../../components/gallery';
import Rsvp from '../../components/rsvp';

import Navbar from '../../components/Navbar';
import Saveday from '../../components/countdown';
import Accomodations from '../../components/accomodations';
import Events from '../../components/events';

const Homepage = () => {
    return (
        <div>
            <Navbar />
            <SimpleSlider />
            <Saveday />
            <Welcome />
            <Location />
            <Accomodations />
            <Events />
            <Gallery />
            <Rsvp />
        </div>
    );
};

export default Homepage;
