import React from 'react';
import './style.css';

const Footer = () => {
    return (
        <div className="footer-area">
            <div className="container">
                {/* <div className="footer-content">
                    <div className="content-sub">
                        <h2>Honeyfund</h2>
                        <span>Coming Soon!</span>
                    </div>
                </div> */}
            </div>
        </div>
    );
};

export default Footer;
