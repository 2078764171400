import React from 'react';

import './style.css';
import Sectiontitle from '../section-title';

const Accomodations = () => {
    return (
        <div className="accomodations-area">
            <div className="container">
                <Sectiontitle section={'Accomodations'} white />
                <div className="row">
                    <div className="col-lg-12">
                        <div className="accomodations-content">
                            <div className="Ceremony-wrap">
                                <div className="row">
                                    <div className="col-lg-5">
                                        <div className="ceromony-img">
                                            <iframe
                                                title="hotel map"
                                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5897.713707303878!2d-71.04112384216445!3d42.34557519298852!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e37a9d46c6f8c9%3A0x8eb1278655e94dd2!2sHyatt%20Place%20Boston%20%2F%20Seaport%20District!5e0!3m2!1sen!2sus!4v1631139623941!5m2!1sen!2sus"
                                                style={{
                                                    border: '0',
                                                    height: '310px',
                                                    width: '100%',
                                                }}
                                                allowFullScreen=""
                                                loading="lazy"
                                            ></iframe>
                                        </div>
                                    </div>
                                    <div className="col-lg-7">
                                        <div className="ceromony-content">
                                            <h3>Hyatt Place Seaport</h3>
                                            <span>
                                                295 Northern Ave, Boston, MA
                                                02210
                                            </span>
                                            <p>
                                                We've blocked off a variety of
                                                rooms at the Hyatt Place Seaport
                                                at a discounted rate. Located
                                                just a short walk from the
                                                venue, enjoy a prime location
                                                overlooking the Boston Harbor in
                                                the heart of the Seaport
                                                District.
                                                <br />
                                                <b>
                                                    NOTE: Make sure to choose
                                                    dates at the top of the page
                                                    before selecting a room
                                                </b>
                                            </p>
                                            <a
                                                rel="noopener noreferrer"
                                                target="_blank"
                                                href="https://www.hyatt.com/shop/boszs?location=Hyatt%20Place%20Boston%20%2F%20Seaport%20District%20&checkinDate=2022-05-12&checkoutDate=2022-05-15&rooms=1&adults=1&kids=0&corp_id=G-COLL"
                                            >
                                                Book A Room Now!
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Accomodations;
