import React, { Component } from 'react';

import Sectiontitle from '../section-title';
import './style.css';

import hyattevent from '../../images/hyatt-event.jpeg';
import placeholderImage from '../../images/ceremony.jpg';
import bagels from '../../images/bagels.jpg';
import citytap from '../../images/city-tap.jpeg';

class SurroundingEvents extends Component {
    render() {
        return (
            <div
                id="surrounding-events"
                className="surrounding-events-section section-padding pb-70"
            >
                <Sectiontitle section={'Surrounding Events'} />
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                            <div className="events-panel">
                                <div className="events-panel-img">
                                    <img src={hyattevent} alt="some alt text" />
                                </div>
                                <div className="events-panel-copy">
                                    <h3>Friday Night</h3>
                                    If you happen to be in town the evening
                                    before our wedding day, we will be grabbing
                                    drinks at the 12th floor lounge of the Hyatt
                                    Place Seaport hotel. This will be very
                                    casual, come and go as you please, but we
                                    will likely get there by 8:30pm. Wedding
                                    guests not staying at the hotel are welcome
                                    to join, just let us know you are coming so
                                    we can get you access.
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                            <div className="events-panel">
                                <div className="events-panel-img">
                                    <img src={citytap} alt="some alt text" />
                                </div>
                                <div className="events-panel-copy">
                                    <h3>Sunday Morning</h3>
                                    While we will not be hosting a formal
                                    postnuptial breakfast or brunch, it can’t
                                    hurt to extend our wedding weekend a little
                                    longer and share some stories of the night
                                    before. Come meet us for a little hair of
                                    the dog (or an extra strong water) at{' '}
                                    <a
                                        href="https://www.citytap.com/location/fort-point/"
                                        rel="noopener noreferrer"
                                        target="_blank"
                                    >
                                        City Tap
                                    </a>{' '}
                                    at 11am Sunday morning.
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                            <div className="events-panel">
                                <div className="events-panel-img">
                                    <img src={bagels} alt="some alt text" />
                                </div>
                                <div className="events-panel-copy">
                                    <h3>Local Bites</h3>
                                    Here’s a few of our favorite places to grab
                                    a quick breakfast or lunch while you’re in
                                    the Seaport area:
                                    <div className="row pt-3">
                                        <div className="col-6 text-center">
                                            <ul>
                                                <li>
                                                    <a
                                                        href="https://www.betterbagelsboston.com/"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        Better Bagels
                                                    </a>
                                                </li>
                                                <li>
                                                    <a
                                                        href="https://www.lolaburger.com/"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        LoLa Burger
                                                    </a>
                                                </li>
                                                <li>
                                                    <a
                                                        href="https://jpaceandson.com/seaport-boston"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        J. Pace &amp; Sons
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="col-6 text-center">
                                            <ul>
                                                <li>
                                                    <a
                                                        href="http://barkingcrab.com/"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        The Barking Crab
                                                    </a>
                                                </li>
                                                <li>
                                                    <a
                                                        href="https://jameshooklobster.com/"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        James Hook
                                                    </a>
                                                </li>
                                                <li>
                                                    <a
                                                        href="http://www.yankeelobstercompany.com/"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        Yankee Lobster
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default SurroundingEvents;
