import React, { Component } from 'react';
import './style.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faSpinner,
    faSmile,
    faSadCry,
} from '@fortawesome/free-solid-svg-icons';

class Rsvp extends Component {
    state = {
        success: false,
        attending: true,
        number_of_guests: '1',
        guest_names: '',
        name: '',
        email: '',
        number_of_rsvp: '',
        attending_events: '',
        notes: '',
        error: {},
        submitting: false,
    };

    changeHandler = (e) => {
        const error = this.state.error;
        error[e.target.name] = '';

        this.setState({
            [e.target.name]: e.target.value,
            error,
        });
    };

    subimtHandler = (e) => {
        e.preventDefault();

        const {
            attending,
            number_of_guests,
            guest_names,
            email,
            number_of_rsvp,
            attending_events,
            notes,
            error,
        } = this.state;

        if (guest_names === '') {
            error.guest_names = 'Please enter at least one guest name';
        }
        if (email === '') {
            error.email = 'Please enter your email';
        }
        if (number_of_rsvp === '') {
            error.number_of_rsvp = 'Select your number of rsvp';
        }
        if (attending_events === '') {
            error.attending_events = 'Select your event list';
        }
        if (notes === '') {
            error.notes = 'Please enter your note';
        }

        if (error) {
            this.setState({
                error,
            });
        }
        if (
            error.name === '' &&
            error.email === '' &&
            error.email === '' &&
            error.number_of_rsvp === '' &&
            error.attending_events === '' &&
            error.notes === ''
        ) {
            this.setState({
                name: '',
                email: '',
                number_of_rsvp: '',
                attending_events: '',
                notes: '',
                error: {},
            });
        }

        this.setState({ submitting: true });

        var ajaxData = [
            { name: 'attending', value: attending },
            { name: 'number_of_guests', value: number_of_guests },
            { name: 'guest_names', value: guest_names },
            { name: 'email', value: email },
        ];

        window.$.ajax({
            url: 'https://api.apispreadsheets.com/data/5nlgv5na4DcGp6Dz/',
            type: 'post',
            data: ajaxData,
            success: () => {
                this.setState({ submitting: false, success: true });
            },
            error: () => {
                alert('There was an error :(');
            },
        });
    };

    render() {
        const {
            attending,
            number_of_guests,
            guest_names,
            name,
            email,
            rsvp,
            attending_events,
            notes,
            error,
            submitting,
            success,
        } = this.state;

        console.log({ attending, number_of_guests, guest_names, email });
        return (
            <div id="rsvp" className="rsvp-area go-rsvp-area section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2 col-md-10 offset-md-1">
                            <div className="rsvp-wrap">
                                {(submitting || success) && (
                                    <div className="rsvp-coming-soon">
                                        {submitting && (
                                            <FontAwesomeIcon
                                                icon={faSpinner}
                                                spin
                                                size="4x"
                                            />
                                        )}
                                        {success && attending && (
                                            <h2
                                                class="section-title"
                                                style={{
                                                    color: '#FFF',
                                                    fontSize: '40px',
                                                }}
                                            >
                                                We can't wait to see you!
                                            </h2>
                                        )}
                                        {success && !attending && (
                                            <h2
                                                class="section-title"
                                                style={{
                                                    color: '#FFF',
                                                    fontSize: '40px',
                                                }}
                                            >
                                                We'll miss you!
                                            </h2>
                                        )}
                                    </div>
                                )}
                                <div className="col-12">
                                    <div className="section-title section-title4 text-center">
                                        <h2>RSVP</h2>
                                        <p>Please respond by April 15, 2022</p>
                                    </div>
                                </div>
                                <form
                                    className="contact-form"
                                    onSubmit={this.subimtHandler}
                                >
                                    <fieldset class="form-group">
                                        <div class="row">
                                            <legend class="col-form-label col-sm-3 pt-0">
                                                Will you join us?!
                                            </legend>
                                            <div class="col-sm-3">
                                                <div class="form-check">
                                                    <input
                                                        class="form-check-input"
                                                        type="radio"
                                                        name="join_us_yes"
                                                        id="join_us_yes"
                                                        checked={attending}
                                                        onChange={(e) => {
                                                            this.setState({
                                                                attending:
                                                                    e.target
                                                                        .checked,
                                                            });
                                                        }}
                                                    />
                                                    <label
                                                        class="form-check-label"
                                                        for="join_us_yes"
                                                    >
                                                        Yes
                                                    </label>
                                                </div>

                                                <div class="form-check disabled">
                                                    <input
                                                        class="form-check-input"
                                                        type="radio"
                                                        name="join_us_no"
                                                        id="join_us_no"
                                                        checked={!attending}
                                                        onChange={(e) => {
                                                            this.setState({
                                                                attending:
                                                                    !e.target
                                                                        .checked,
                                                            });
                                                        }}
                                                    />
                                                    <label
                                                        class="form-check-label"
                                                        for="join_us_no"
                                                    >
                                                        No
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="form-group col-md-6">
                                                <label for="number_of_guests">
                                                    Number Of Guests
                                                </label>
                                                <select
                                                    id="number_of_guests"
                                                    className="form-control"
                                                    onChange={
                                                        this.changeHandler
                                                    }
                                                    value={number_of_guests}
                                                    name="number_of_guests"
                                                >
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                    <option value="3">3</option>
                                                    <option value="4">4</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <label for="guest_names">
                                                    Guest Name(s)
                                                </label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    value={guest_names}
                                                    onChange={
                                                        this.changeHandler
                                                    }
                                                    placeholder="Enter name(s)"
                                                    id="guest_names"
                                                    name="guest_names"
                                                />
                                            </div>
                                            <div className="col-sm-6">
                                                <label for="email">Email</label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    value={email}
                                                    onChange={
                                                        this.changeHandler
                                                    }
                                                    placeholder="Email Address"
                                                    id="email"
                                                    name="email"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12 text-center">
                                            <button
                                                id="submit"
                                                type="submit"
                                                className="submit"
                                            >
                                                Submit
                                            </button>
                                        </div>
                                    </fieldset>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Rsvp;
